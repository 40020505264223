import React, { useEffect, useMemo } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  ShoppingCart as ShoppingCartIcon,
  Layout as LayoutIcon,
  Users as UsersIcon,
  Grid as GridIcon,
  Monitor as MonitorIcon,
  Settings as SettingsIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { getUserFunction } from "../../../reducers/accountReducer";

const navConfig = [
  // {
  //   items: [
  //     {
  //       title: 'Início',
  //       icon: LayoutIcon,
  //       href: '/app/reports/dashboard'
  //     }
  //   ]
  // },
  {
    subheader: 'Administrar',
    items: [
      {
        title: 'Vendas',
        icon: ShoppingCartIcon,
        roles: ['separator', 'admin', 'owner', 'attendant', 'seller'],
        href: '/app/management/orders',
        items: [
          {
            title: 'Lista de vendas',
            href: '/app/management/orders'
          },
          {
            title: 'Carrinhos abandonados',
            href: '/app/management/abandoned-carts'
          },
        ]
      },
      {
        title: 'Produtos',
        icon: GridIcon,
        roles: ['admin', 'owner'],
        href: '/app/management/products',
        items: [
          {
            title: 'Lista de produtos',
            href: '/app/management/products'
          },
          {
            title: 'Adicionar produto',
            href: '/app/management/products/create'
          },
          {
            title: 'Categorias',
            roles: ['admin', 'owner'],
            href: '/app/management/categories'

          }
        ]
      },
      {
        title: 'Seleção de Produtos',
        icon: GridIcon,
        roles: ['seller', 'admin', 'owner'],
        href: '/app/management/select-products',
        items: [
          {
            title: 'Lista de links',
            href: '/app/management/select-products/list'
          },
          {
            title: 'Criar Link',
            href: '/app/management/select-products/create'
          },
        ]
      },
      {
        title: 'Campanhas',
        icon: GridIcon,
        roles: ['admin', 'owner'],
        href: '/app/management/select-products',
        items: [
          {
            title: 'Lista de campanhas',
            href: '/app/management/campaigns/list'
          },
          {
            title: 'Criar Campanha',
            href: '/app/management/campaigns/create'
          },
        ]
      },
      {
        title: 'Clientes',
        icon: UsersIcon,
        roles: ['seller', 'admin', 'owner'],
        href: '/app/management/customers',
        items: [
          {
            title: 'Lista de clientes',
            href: '/app/management/customers'
          },
        ]
      },
      {
        title: 'Usuários',
        icon: UsersIcon,
        roles: ['admin', 'owner'],
        href: '/app/management/accounts',
        items: [
          {
            title: 'Lista de usuários',
            href: '/app/management/accounts'
          },
          {
            title: 'Criar usuário',
            href: '/app/management/accounts/create'
          },
        ]
      },
    ]
  },
  {
    subheader: 'Configurações',
    items: [
      {
        title: 'Configurações',
        icon: SettingsIcon,
        items: [
          {
            title: 'Notificações',
            href: '/app/settings',
          },
        ]
      }
    ]
  }
  // {
  //   subheader: 'Personalizar',
  //   items: [
  //     {
  //       title: 'Minha Loja',
  //       icon: MonitorIcon,
  //       href: '/app/',
  //     }
  //   ]
  // },
];

function renderNavItems({ items, role, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, role, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  role,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.roles && !item.roles.includes(role)) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 140,
    height: 140
  },
  logo: {
    maxHeight: 48
  },
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  const role = useSelector(getUserFunction);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const filterNavItems = (items) => {
    return items.filter((item) => !item.roles || item.roles.includes(role));
  };

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.companyAvatar}
              />
            </RouterLink>
          </Box>
          <Box
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.store_operator.company.name}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map((config, index) => (
            <List
              key={`${index}-${config.subheader}`}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: filterNavItems(config.items), pathname: location.pathname, role })}
            </List>
          )
          )}
        </Box>
        <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            display="flex"
            justifyContent="center"
          >
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Versão 1.6
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
